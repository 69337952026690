import React from "react";
import { useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import { baseUrl } from "./apiUrl";

const MailSender = ({
  date_from,
  date_to,
  totalRegularHours,
  regularHoursRate,
  erningFromRegularHours,
  totalRegularHours24072023,
  regularHoursRate24072023,
  erningFromRegularHours24072023,
  totalRegularHours01022025,
  regularHoursRate01022025,
  erningFromRegularHours01022025,
  totalOperatorHours,
  operatorHoursRate,
  erningFromOperatorHours,

  totalOperatorHoursWeekend23102022,
  operatorHoursWeekend23102022Rate,
  erningFromOperatorHoursWeekend23102022,

  totalOperatorHours24072023,
  operatorHoursRate24072023,
  erningFromOperatorHours24072023,

  totalOperatorHours01022025,
  operatorHoursRate01022025,
  erningFromOperatorHours01022025,

  totalOperatorHoursWeekend24072023,
  operatorHoursWeekendRate24072023,
  erningFromOperatorHoursWeekend24072023,

  totalOperatorHoursWeekend,
  operatorHoursWeekendRate,
  erningFromOperatorHoursWeekend,

  totalRegularHoursWeekend,
  regularHoursWeekendRate,
  erningFromRegularHoursWeekend,

  totalRegularHoursHoliday,
  regularHoursHolidayRate,
  erningFromRegularHoursHoliday,

  totalOperatorHoursHoliday,
  operatorHoursHolidayRate,
  erningFromOperatorHoursHoliday,

  totalRegularHoursCorrection,
  regularHoursCorrectionRate,
  erningFromRegularHoursCorrection,

  totalOperatorHoursCorrection,
  operatorHoursCorrectionRate,
  erningFromOperatorHoursCorrection,

  totalErn,
  totalHours,

  bonus_percentage,
  bonusAmount,
  extra_amount,
  totalErnWithBonus,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [additionalText, setAdditionalText] = useState("");
  const [isMailSent, setIsMailSent] = useState(false);

  const handleButtonClick = () => {
    if (!isMailSent) {
      setShowModal(true);
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    axios
      .post(`${baseUrl}/sendMail.php`, {
        date_from,
        date_to,
        totalRegularHours,
        regularHoursRate,
        erningFromRegularHours,
        totalRegularHours24072023,
        regularHoursRate24072023,
        erningFromRegularHours24072023,
        totalRegularHours01022025,
        regularHoursRate01022025,
        erningFromRegularHours01022025,
        totalOperatorHours,
        operatorHoursRate,
        erningFromOperatorHours,

        totalOperatorHours24072023,
        operatorHoursRate24072023,
        erningFromOperatorHours24072023,

        totalOperatorHours01022025,
        operatorHoursRate01022025,
        erningFromOperatorHours01022025,

        totalOperatorHoursWeekend23102022,
        operatorHoursWeekend23102022Rate,
        erningFromOperatorHoursWeekend23102022,
        totalOperatorHoursWeekend24072023,
        operatorHoursWeekendRate24072023,
        erningFromOperatorHoursWeekend24072023,
        totalOperatorHoursWeekend,
        operatorHoursWeekendRate,
        erningFromOperatorHoursWeekend,
        totalRegularHoursWeekend,
        regularHoursWeekendRate,
        erningFromRegularHoursWeekend,
        totalRegularHoursHoliday,
        regularHoursHolidayRate,
        erningFromRegularHoursHoliday,
        totalOperatorHoursHoliday,
        operatorHoursHolidayRate,
        erningFromOperatorHoursHoliday,
        totalRegularHoursCorrection,
        regularHoursCorrectionRate,
        erningFromRegularHoursCorrection,
        totalOperatorHoursCorrection,
        operatorHoursCorrectionRate,
        erningFromOperatorHoursCorrection,
        totalErn,
        totalHours,
        bonus_percentage,
        bonusAmount,
        extra_amount,
        totalErnWithBonus,
        additionalText: additionalText,
      })
      .then(() => {
        alert("Success. Mail Sent!");
        setIsMailSent(true);
        closeModal();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const closeModal = () => {
    setShowModal(false);
    setAdditionalText("");
  };

  const modalStyles = {
    content: {
      width: "290px",
      height: "445px",
      margin: "auto",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.7)",
      padding: "15px",
    },
  };

  return (
    <React.Fragment key={isMailSent}>
      <button
        className="btn btn-primary btn-lg mt-3 m-2 col-md-2"
        onClick={handleButtonClick}
        disabled={isMailSent}
      >
        {isMailSent ? "Mail Sent!" : "Send Mail"}
      </button>
      <Modal isOpen={showModal} onRequestClose={closeModal} style={modalStyles}>
        <form onSubmit={handleSubmitForm}>
          <div>
            <label
              htmlFor="additionalText"
              className="form-label mt-2 mb-1 text-primary"
            >
              Additional Text:
            </label>
            <textarea
              id="additionalText"
              value={additionalText}
              onChange={(e) => setAdditionalText(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="row justify-content-center">
            <button type="submit" className="btn btn-primary m-3 col-md-4">
              OK
            </button>
            <button
              onClick={closeModal}
              className="btn btn-primary m-3 col-md-4"
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default MailSender;
